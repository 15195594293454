
import '../styles/Pages/Home.css';
import { Button } from 'react-bootstrap';
import Partners from '../components/Partners';
import Footer from '../components/Footer';
import heroimage from '../assets/images/hero_image.png';
import KitchenTechnology from '../components/KitchenTechnology';
import Team from '../components/Team';
import Technology from '../components/Technology';
import Header from '../components/Header';
import SenseProduct from '../components/SenseProduct';
const Home = () => {

  return (
    <>
      <Header />

      <div className="content desktop-view">
        <div className="row intro">
          <div className="font-headings col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <div className="heading ">
              Cliste is <span style={{ color: "#F11E1E" }}>Innovating.</span>
            </div>
            <p className="font-paragraphs text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
              in reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <Button className="intro-btn font-paragraphs">
              View Products
            </Button>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 hero-image">
            <img src={heroimage} className="img-fluid" alt="hero image" style={{height:"100%", width:"100%", objectFit:"cover"}}/>
          </div>
        </div>
      </div>

      <div className="content mobile-view">
        <div className="row intro">
          <div className="font-headings col-12">
            <div className="col-12 hero-image">
              <img src={heroimage} className="img-fluid" alt="img" style={{height:"100%", width:"100%",objectFit:"cover"}}/>
            </div>
            <div className="heading ">
              Cliste is <span style={{ color: "#F11E1E" }}>Innovating.</span>
            </div>
            <p className="font-paragraphs text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
              in reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <Button className="intro-btn font-paragraphs">
              View Products
            </Button>
          </div>
        </div>
      </div>
      <SenseProduct text={"We've got an app for that, because who needs more clutter in their life?"} content={"Transform Your Kitchen Experience with Cliste Sense : The ultimate kitchen assistant in the palm of your hand.  Download the Cliste Sense app now and start cooking with confidence!"}/>
      <Team />
      <Technology />
      <Partners />
      <KitchenTechnology />
      <Footer />
    </>
  );
}

export default Home;