import "./App.css";
import Application  from "../src/pages/Application";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import WaitList from "../src/pages/WaitList";
import PrivacyPolicy from "../src/pages/PrivacyPolicy";
import Contact from "../src/pages/Contact";
import Store from "../src/pages/Store";
import Product from "../src/pages/Product";
import Home  from "../src/pages/Home";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="/application" element={<Application />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/store" element={<Store />} />
          <Route exact path="/" element={<WaitList />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
