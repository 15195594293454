import React from 'react'
import '../styles/Components/SectionIntroduction.css';
function SectionIntroduction({title, content, img, subtitle}) {
  return (
    <div className='section-intro'>
        <div className=''>
            <h2 className='font-headings story-content-title'>{title}</h2>
        </div>
        {
          subtitle ? (<div>
              <h4 className ='font-paragraphs  section-intro-subheading'>where it came from and where it is going.</h4>
          </div>) : (
            null
          )
        }
        
        <div className='font-paragraphs story-text'>
            {content}
        </div>
        {
          img ? (<div className='team-video-section'>
          <img src={img} alt="" className="founder-message-video" />
          </div>) :(
            null
          )
        }
        
    </div>
  )
}

export default SectionIntroduction;