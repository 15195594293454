import '../styles/Pages/Product.css';
import Footer from '../components/Footer';
import image1 from '../assets/images/glance.png';
import KitchenTechnology from '../components/KitchenTechnology';
import { Button } from 'react-bootstrap';
import Header from '../components/Header';
import SenseProduct from '../components/SenseProduct';
import shoppingcartLogo from '../assets/images/shoppingcart.svg';
import colofilter from '../assets/images/colorfilter.svg';
import backwarditem from '../assets/images/backwarditem.svg';
import bubble from '../assets/images/bubble.svg';
import shuttlstock from '../assets/images/shutterstock.svg';
import notepad from '../assets/images/notepad2.svg';
import im1 from '../assets/images/temp.png';
import im2 from '../assets/images/kitchen2.png';
import im3 from '../assets/images/expiry-date.png';
import im4 from '../assets/images/recipe.png';
import clistemobileapp from '../assets/images/clistemobileapp.png';
const Product = () => {

  const dummy=[
    {
      image_url:im1,
      title:"SENSE TEMPERATURE OF YOUR FOOD ITEMS",
      content:"Cliste Sense's temperature sensing technology helps you maintain the quality of your ingredients, ensuring that they stay fresh and safe for consumption. "
    },
    {
      image_url:im2,
      title:"optimize your kitchen With modern technology",
      content:"Upgrade your kitchen experience the convenience of modern technology, Make cooking effortless and reduce food waste with the Cliste Sense."
    },
    {
      image_url:im3,
      title:"GET FOOD Expiration date MUCH EARLY",
      content:"Cliste Sense detect the expiration dates of your food items, giving you an early warning to use them up before they go expire & turn lapse."
    },
    {
      image_url:im4,
      title:"Effortlessly uncover recipe recommendations",
      content:"Discover recipe ideas effortlessly. Cliste Sense suggests recipes based on the ingredients you have on hand, making meal planning better. Say goodbye to boring meals and hello to delicious new dishes."
    }
  ]
  return (
    <>
      <Header selectedPageIdx={0} />

      <div className="content">
        <div className="intro">
          <div className="hero-image">
            <img
              src={image1}
              className="img-fluid"
              alt="hero image"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>

      <div className="working">
        <div className="font-headings working-heading">What it does</div>
        <div className="working-content">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 working-content-mob-img" style={{ marginTop:"60px" }}>
              
              <img src={clistemobileapp} alt="img"></img>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-9 row">
              {dummy.map((data, index) => (
                <div className="col-xs-12 col-sm-12 col-md-6 work-card" key={index} style={{color:"rgba(250, 250, 250, 1)", marginTop:"60px"}} >
                  <div>
                    <img src={data.image_url} alt="img" style={{height:"100px", objectFit:"cover"}}/>
                  </div>
                  <div style={{marginTop:"28px"}} className='work-card'>
                    <h4 className='font-paragraphs work-card-title'>{data.title}</h4>
                  </div>
                  <div style={{marginTop:"14px"}} className='work-card'>
                    <p className='font-paragraphs work-card-para' style={{marginBottom:"0"}}>{data.content}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#315C66" }}>
        <SenseProduct text={"There’s an app for that"} content={"Pair your Cliste Sense with the app, onboard the jar with food ingredients you have and start receiving real time updates about food temperature, expiration date, recipe recommendations and much more. This is ultimate kitchen assistant in the plan of your hand. Download Cliste app now."}/>
        <div className="mockup-section">
          <div className="font-headings mockup-section-heading">
            Cliste Sense at fleeting glance
          </div>
          <div className="font-headings mockup-section-sub-heading">
            Automate your kitchen food items with cliste sense
          </div>
          <p className="font-paragraphs mockup-section-sub-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrudLorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
          </p>
          <div className="row app-section-image-video">
            <div className="mockup-section-detail col-xs-12 col-sm-6 col-md-4 col-lg-4">
              <ul
                style={{ listStyle: "none", padding: 0 }}
                className="mockup-section-ul"
              >
                <li className="mockup-section-list-item">
                  <img src={colofilter} alt="image" />
                  <p className="font-paragraphs mockup-section-text">
                    Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor
                    sit
                  </p>
                </li>
                <li className="mockup-section-list-item">
                  <img src={backwarditem} alt="image" />
                  <p className="font-paragraphs mockup-section-text">
                    Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor
                    sit
                  </p>
                </li>
                <li className="mockup-section-list-item">
                  <img src={bubble} alt="image" />
                  <p className="font-paragraphs mockup-section-text">
                    Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor
                    sit
                  </p>
                </li>
                <li className="mockup-section-list-item">
                  <img src={shuttlstock} alt="image" />
                  <p className="font-paragraphs mockup-section-text">
                    Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor
                    sit
                  </p>
                </li>
                <li className="mockup-section-list-item">
                  <img src={notepad} alt="image" />
                  <p className="font-paragraphs mockup-section-text">
                    Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor
                    sit
                  </p>
                </li>
              </ul>
              <div className="mockup-section-download mobile-view">
                <Button className="mockup-video">
                  <img
                    src={shoppingcartLogo}
                    alt="cart logo"
                    className="app-section-cart-logo"
                  />
                  <span className="mockup-section-download-text">
                    {" "}
                    Get Cliste Sense
                  </span>
                </Button>
              </div>
            </div>

            <div className="mockup-section-video col-xs-12 col-sm-6 col-md-8 col-lg-8">
              <div style={{ height: "80%" }}>
                <img
                  src={image1}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>
              <div className="mockup-section-download desktop-view">
                <Button className="mockup-video">
                  <img
                    src={shoppingcartLogo}
                    alt="cart logo"
                    className="app-section-cart-logo"
                  />
                  <span className="mockup-section-download-text">
                    {" "}
                    Get Cliste Sense
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <KitchenTechnology />
      <Footer />
    </>
  );
}

export default Product;