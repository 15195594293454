import React from 'react';
import Masonry from '@mui/lab/Masonry';
import Box from '@mui/material/Box';
import '../styles/Components/MasonaryCard.css';

function MasonryCard({data}) {
    return (
      <Box>
        {/* style={{height:Math.floor(Math.random() * (600 - 200 + 1)) + 200}} */}
        <Masonry
          columns={2}
          spacing={4}
          style={{ margin: "0" }}>
            {data.map((data, index) => (
                <div key={index} className="tech-card-images" style={{height: window.innerWidth > 768 ? data.height : data.mob_height}}>
              <div className='tech-mas-card-content'>
                 <img
                src={`${data.image_url}`}
                alt={data.title}
                loading="lazy"
                style={{objectFit:"cover"}}/> 

                <div className="tech-card-details" style={{width:"100%"}}>
                  <h6 className="font-headings tech-card-title">
                    {data.title}
                  </h6>
                  <p className="font-paragraphs tech-card-text">
                    {data.content}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Masonry>
      </Box>
    );
}

export default MasonryCard