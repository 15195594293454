import '../styles/Components/Team.css';
import { teams_data_1 } from "../data";
import image1 from '../assets/images/founderpic.png';
import SectionIntroduction from './SectionIntroduction';
const Team = () => {
  return (
    <>
      <div className='team'>
        <SectionIntroduction title = {"Cliste Odyssey"} content = {"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. MagnaUllamcorper velit sed ullamcorper morbi tincidunt. Ac placerat vestibulum lectus mauris. Netus etmalesuada fames ac. In dictum non consectetur a. Et sollicitudin acorci phasellus egestas tellus rutrum. Eget lorem dolor sed viverraipsum nunc. Odio ut sem nulla pharetra. Varius duis at consecteturlorem donec massa sapien. Et molestie ac feugiat sed. Eget nullamnon nisi est sit amet facilisis. Vel fringilla est Ut faucibus pulvinar elementuminteger enim. Est lorem ipsum dolor sit amet consectetur adipiscingelit."} subtitle={"where it came from and where it is going."}/>

          <SectionIntroduction title = {"Founder's message"} content = {"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. MagnaUllamcorper velit sed ullamcorper morbi tincidunt. Ac placerat vestibulum lectus mauris. Netus etmalesuada fames ac. In dictum non consectetur a. Et sollicitudin acorci phasellus egestas tellus rutrum. Eget lorem dolor sed viverraipsum nunc. Odio ut sem nulla pharetra. Varius duis at consecteturlorem donec massa sapien. Et molestie ac feugiat sed. Eget nullamnon nisi est sit amet facilisis. Vel fringilla est Ut faucibus pulvinar elementuminteger enim. Est lorem ipsum dolor sit amet consectetur adipiscingelit."} img={image1}/>
         
          <div className="team-section">
            <h2 className="font-headings team-section-heading">
            The People behind Cliste
            </h2 >
            <h4 className='font-paragraphs team-section-subheading'>
            Our team is a mix of tech geeks, coffee enthusiasts, and gamers. We're united by our love for smart home automation.
            </h4>
            <div className="row team-section-1">
              {teams_data_1.map((team, index) => {
                return (
                  <div
                    className="team-content col-xs-6 col-sm-6 col-md-6 col-lg-3"
                    key={index}
                  >
                    <div className="card team-cards" style={{border:"none"}}>
                      <div>
                      <img
                        src={team.image_url}
                        className="card-img-top"
                        alt="..."
                      />
                      </div>
                      <div className="card-body team-card-title">
                        <h5 className="card-title font-headings team-card-heading-1">
                          {team.name}
                        </h5>
                        <h5 className="card-title font-headings team-card-heading-2">
                          {team.position}
                        </h5>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="sticky-box">
              <h2 className="font-headings sticky-box-heading">Become one of us</h2>
              <p className="font-paragraphs sticky-box-text">
                Are you interested to join our team Cliste and build some
                exciting products with us ?<br />
                If yes, then please share your motivation and updated resume at{" "}
                <strong>joincliste@cliste.io</strong>
              </p>
            </div>
          </div>
        </div>
    </>
  );
}

export default Team;