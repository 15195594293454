import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    colors: {
      primary:"#363638",
      secondary:"#315C66",
      secondary_2:" #048B9E",
      tertiary:"#FAFAFA"
    },
  },
});

export default theme;