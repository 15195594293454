import '../styles/Components/SenseProduct.css';
import clistemobileapp from '../assets/images/clistemobileapp.png';
import appstore from '../assets/images/apple-button.png';
import googleplay from '../assets/images/google-play.png';
import { Link } from 'react-router-dom';


let dummy=[
    {
        image_url:clistemobileapp
    },
    {
        image_url:clistemobileapp
    },
    {
        image_url:clistemobileapp
    },
]

const SenseProduct = ({ text, content}) => {

    if(window.innerWidth <= 576){
        dummy = [dummy[1]];
    }
    return (
      <div className="app-section">
        <div className="font-headings app-section-heading">{text}</div>
        <div className="font-headings app-section-sub-heading">
        Keep track your kitchen food items with cliste Sense
        </div>
        <p className="font-paragraphs app-section-text desktop-view">
        {content}
        </p>
        <div
          className='app-section-app-images'
        >
          {dummy.map((image, index) => (
            <div key={index}>
              <img src={image.image_url} alt="img" style={{width:"100%"}}/>
            </div>
          ))}
        </div>
        <div className="apply-app-download">
          <Link to="/" style={{ marginRight: "32px" }}>
            <img
              src={googleplay}
              alt="Google Play Store"
              className="img-fluid"
            />
          </Link>
          <Link to="/">
            <img src={appstore} alt="App Store" className="img-fluid" />
          </Link>
        </div>
      </div>
    );
}

export default SenseProduct;