import '../styles/Components/Technology.css'
import MasonryCard from './MasonryCard';
import { tech_data } from '../data';
const Technology = () => {
    return (
        <>
            <div className='tech-wrapper'>
                <div >
                    <h2 className="font-headings tech-adv-section-heading">
                    The Future of Everyday Living Insights and Updates from Cliste world
                    </h2>
                </div>
                <MasonryCard data={ tech_data}/>
            </div>
        </>
    )
}

export default Technology;