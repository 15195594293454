import '../styles/Pages/PrivacyPolicy.css';
const PrivacyPolicy =()=>{
    return(
        <>
        <div className='privacy-content'>
            <div>
            <h5 className='privacy-heading font-headings'>Privacy and Policy</h5>
            <p>last updated on 27th Feb 2023</p>
            </div>
            
        </div>
        </>
    )
}

export default PrivacyPolicy;