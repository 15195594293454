import '../styles/Pages/Application.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import image from '../assets/images/temp-img.jpg';
import clistemobileapp from '../assets/images/clistemobileapp.png';
import appstore from '../assets/images/apple-button.png';
import googleplay from '../assets/images/google-play.png';
import { Link } from 'react-router-dom';
import KitchenTechnology from '../components/KitchenTechnology';
const Application = () => {
    return (
        <>
            <Header selectedPageIdx={1} />
                <div className='content apply-mobile-image-div1 ' style={{marginBottom:"257px"}}>
                    <h2 className='font-headings apply-section-heading'>Tired of playing the guessing game with your next cuisine?
                    <br/> Let Cliste app do the heavy lifting. It's like having a<br/> 
                    sixth sense, but for your kitchen.......</h2>
                </div>
                <div className='apply-mobile-image-div2 '>
                    <div>
                    <img src={clistemobileapp} alt="image" className='img-fluid apply-mobile-image-div2-img'>
                    </img>
                    </div>
                    <div style={{marginTop:"32px", marginBottom:"0"}} className='apply-mobile-subheading1'>
                        <h4 className ='font-headings apply-mobile-subheading' style={{marginBottom:"0"}}> The easiest way to level up your kitchen game. Using Cliste app<br/>  experience the future of effortless and sustainable living.</h4>
                    </div>
                    <div style={{marginTop:"32px", marginBottom:"0"}} className='apply-mobile-subheading1'>
                        <p className='font-paragraphs apply-mobile-para' style={{marginBottom:"0"}}>Ready to simplify your kitchen ? The ultimate kitchen assistant in the palm of your <br/> hand.  Download the Cliste Sense app now and start cooking with confidence!</p>
                    </div>
                    <div className='apply-app-download'>
                        <Link to="/"  style={{marginRight:"32px"}}>
                            <img src={googleplay} alt="Google Play Store" className='img-fluid' />
                            </Link>
                        <Link to="/">
                            <img src={appstore} alt="App Store" className="img-fluid" />
                        </Link>
                    </div>
                </div>
                <div className='app-features'>
                    <div className='row'>
                        <div className='features-left-aligned col-sm-6'>
                            <div className='font-headings '>
                                <p className='font-paragraphs app-features-text'>Cliste app : Your Ultimate Kitchen Companion</p>
                            </div>
                            <div className="col feature-content">
                                <div className="card feature-card">
                                    <img src={image} className="card-img-top" alt="..." />
                                    <div className="card-body feature-content-card">
                                        <h5 className="card-title feature-card-title">Feature 2 - Recipe</h5>
                                        <p className="card-text font-paragraphs feature-card-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt augue. Ac feugiat sed lectus vestibulum. Gravida quis blandit turpis cursus in. In fermentum posuere.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col feature-content">
                                <div className="card feature-card">
                                    <img src={image} className="card-img-top" alt="..." />
                                    <div className="card-body feature-content-card">
                                        <h5 className="card-title feature-card-title">Feature 2 - Recipe</h5>
                                        <p className="card-text font-paragraphs feature-card-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt augue. Ac feugiat sed lectus vestibulum. Gravida quis blandit turpis cursus in. In fermentum posuere.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col feature-content">
                                <div className="card feature-card">
                                    <img src={image} className="card-img-top" alt="..." />
                                    <div className="card-body feature-content-card">
                                        <h5 className="card-title feature-card-title">Feature 2 - Recipe</h5>
                                        <p className="card-text font-paragraphs feature-card-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt augue. Ac feugiat sed lectus vestibulum. Gravida quis blandit turpis cursus in. In fermentum posuere.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='features-right-aligned col-sm-6'>
                            <div className="col feature-content">
                                <div className="card feature-card">
                                    <img src={image} className="card-img-top" alt="..." />
                                    <div className="card-body feature-content-card">
                                        <h5 className="card-title feature-card-title">Feature 2 - Recipe</h5>
                                        <p className="card-text font-paragraphs feature-card-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt augue. Ac feugiat sed lectus vestibulum. Gravida quis blandit turpis cursus in. In fermentum posuere.</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col feature-content">
                                <div className="card feature-card">
                                    <img src={image} className="card-img-top" alt="..." />
                                    <div className="card-body feature-content-card">
                                        <h5 className="card-title feature-card-title">Feature 2 - Recipe</h5>
                                        <p className="card-text font-paragraphs feature-card-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt augue. Ac feugiat sed lectus vestibulum. Gravida quis blandit turpis cursus in. In fermentum posuere.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col feature-content">
                                <div className="card feature-card">
                                    <img src={image} className="card-img-top" alt="..." />
                                    <div className="card-body feature-content-card">
                                        <h5 className="card-title feature-card-title">Feature 2 - Recipe</h5>
                                        <p className="card-text font-paragraphs feature-card-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt augue. Ac feugiat sed lectus vestibulum. Gravida quis blandit turpis cursus in. In fermentum posuere.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col feature-content">
                                <div className="card feature-card">
                                    <img src={image} className="card-img-top" alt="..." />
                                    <div className="card-body feature-content-card">
                                        <h5 className="card-title feature-card-title">Feature 2 - Recipe</h5>
                                        <p className="card-text font-paragraphs feature-card-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam sem fringilla ut morbi tincidunt augue. Ac feugiat sed lectus vestibulum. Gravida quis blandit turpis cursus in. In fermentum posuere.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <KitchenTechnology/>
            <Footer />
        </>
    )

}


export default Application