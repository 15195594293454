import group11 from './assets/images/group11.png';
import group12 from './assets/images/group12.png';
import group13 from './assets/images/group13.png';
import group14 from './assets/images/group14.png';
import group15 from './assets/images/group15.png';
import group16 from './assets/images/group16.png';
import group17 from './assets/images/group17.png';
import group18 from './assets/images/group18.png';
import ai from './assets/images/ai.png';
import connectivity from './assets/images/connectivity.png';
import futureinnovations from './assets/images/futureinnovations.png';
import securityandprivacy from './assets/images/securityandprivacy.png';
import sensors from './assets/images/sensors.png';
import ui from './assets/images/userinterface.png';
export const teams_data_1=[
    {
        image_url:group14,
        name:"abc",
        position:"CEO+Founder"
    },
    {
        image_url:group12,
        name:"abc",
        position:"UI/UX Designer"
    },
    {
        image_url:group13,
        name:"abc",
        position:"Tech Lead"
    },
    {
        image_url:group11,
        name:"abc",
        position:"Product Manager"
    },
    {
        image_url:group15,
        name:"abc",
        position:"Data Engineer"
    },
    {
        image_url:group16,
        name:"abc",
        position:"Machine Learning"
    },
    {
        image_url:group17,
        name:"abc",
        position:"Hardware Engineer"
    },
    {
        image_url:group18,
        name:"abc",
        position:"Human Resource"
    },
]

export const teams_data_2=[
    {
        image_url:group15,
        name:"abc",
        position:"Data Engineer"
    },
    {
        image_url:group16,
        name:"abc",
        position:"Machine Learning"
    },
    {
        image_url:group17,
        name:"abc",
        position:"Hardware Engineer"
    },
    {
        image_url:group18,
        name:"abc",
        position:"Human Resource"
    },

]

export const tech_data = [
    {
        image_url : sensors,
        title:"Sensors",
        content:"Discuss the advanced sensors used in Cliste Sense and how they accurately",
        height:"378px",
        mob_height:"182px"

    },
    {
        image_url : connectivity,
        title:"Connectivity",
        content:"Explain how Cliste Sense connects to other smart devices in your home, ",
        height:"790px",
        mob_height:"395px"

    },
    {
        image_url : ai,
        title:"Artificial Intelligence",
        content:"Describe how Cliste Sense uses artificial intelligence to recommend recipes",
        height:"378px",
        mob_height:"182px"

    },
    {
        image_url : ui,
        title:"User Interface",
        content:"Showcase the user interface of Cliste Sense and how it displays inventory, recipe recommendations.",
        height:"893px",
        mob_height:"405px"

    },
    {
        image_url : futureinnovations,
        title:"Furture Innovations",
        content:"Highlight how Cliste Sense utilizes cloud services to store and analyze data to",
        height:"435px",
        mob_height:"192px"

    },
    {
        image_url : securityandprivacy,
        title:"Security and Privacy",
        content:"Discuss the measures taken by Cliste to ensure data privacy and device security.",
        height:"424px",
        mob_height:"182px"

    }
]